<template>
  <v-container v-if="!_.isEmpty(creditTransferEditable)">
    <!-- Banner header -->
    <Banner :title="`Resumen del envío (estado: ${creditTransferEditable.status})`">
      <template v-slot:actions>
        <v-btn
          v-if="permissionsBannerActions.changeToSent.includes(user.role) && creditTransferEditable.status === 'CREATED'"
          @click="dialogChangeToSent = true"
          small
        >
          Pasar a enviado
        </v-btn>
        <v-btn
          v-if="!editingMoneyTransfer"
          v-show="
            permissionsBannerActions.byStatus[creditTransferEditable.status].edit.includes(user.role) &&
            creditTransferEditable.status === 'CREATED'
          "
          @click="editingMoneyTransfer = true"
          small
        >
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-divider vertical inset class="mx-4" />
        <v-btn
          color="red"
          v-show="
            permissionsBannerActions.byStatus[creditTransferEditable.status].delete.includes(user.role) &&
            creditTransferEditable.status === 'CREATED'
          "
          @click="dialogDeleteCreditTransfer = true"
          small
        >
          <v-icon color="white" small>delete</v-icon>
        </v-btn>
      </template>
    </Banner>

    <div v-if="creditTransferEditable.status !== 'CREATED' && creditTransferEditable.sent_at" class="secondary pa-4 mt-4">
      <p class="mt-4"><b>Fecha de envío:</b> {{ formatDateL(creditTransferEditable.sent_at) }}</p>
    </div>

    <!-- Credits transfers -->
    <v-card class="mt-5">
      <v-toolbar color="primary" dense>
        <span>Inversiones</span>
      </v-toolbar>
      <v-card-text>
        <v-expansion-panels v-model="panelOffices" multiple class="mt-2">
          <v-expansion-panel
            v-for="office in offices.filter((e) => creditTransferEditable.offices_ids.includes(e.id))"
            :key="office.id"
            class="mb-3 secondary"
          >
            <v-expansion-panel-header class="py-0 grey lighten-1">
              {{ office.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="white">
              <v-expansion-panels class="mt-2">
                <v-expansion-panel
                  v-for="project in creditTransferEditable.credits_transfers.filter((e) => e.office_id === office.id)"
                  :key="project.project_id"
                  class="mb-3 secondary"
                >
                  <v-expansion-panel-header class="py-0">
                    {{ project.project_id }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="white">
                    <ProjectSummaryTable
                      :key="projectSummaryTableKey"
                      :project="project"
                      :isEditable="editingMoneyTransfer"
                      @change="needToRecalculate = true"
                    />
                    <div v-if="editingMoneyTransfer && creditTransferEditable.credits_transfers.length > 1" class="text-right mt-3">
                      <v-btn small @click="removeProjectFromCreditTransfer(project), (needToRecalculate = true)">Quitar del envío</v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <!-- Accounts -->
    <v-card class="mt-5">
      <v-toolbar color="primary" dense>
        <span>Cuentas</span>
      </v-toolbar>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Número</th>
              <th>Banco</th>
              <th>Tipo</th>
              <th>Disponible</th>
              <th>Fianza</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in creditTransferEditable.accounts" :key="account.id">
              <td>{{ account.number ? account.number : "-" }}</td>
              <td>{{ account.bank ? account.bank : "-" }}</td>
              <td>{{ account.type }}</td>
              <td>
                <v-text-field
                  v-model.number="account.balance"
                  type="number"
                  filled
                  dense
                  class="mt-2 mb-n4"
                  :readonly="!editingMoneyTransfer"
                  :rules="formRules.numberRulesAllowZero"
                  @input="needToRecalculate = true"
                />
              </td>
              <td>{{ account.bail }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- Amounts summary -->
    <v-card class="mt-5">
      <v-toolbar color="primary" dense>
        <span>Cantidades</span>
        <v-spacer />
        <v-btn v-if="needToRecalculate" small @click="calculateData()">
          recalcular
          <v-icon v-if="needToRecalculate" size="20" color="error" class="ml-1">report_gmailerrorred</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-form ref="formAmountsSummary" v-model="formAmountsSummaryValid">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Enviado:</th>
                  <td>
                    {{ creditTransferEditable.amount_sent }}
                  </td>
                </tr>
                <tr>
                  <th>Disponible en banco:</th>
                  <td>
                    {{ creditTransferEditable.bank_amount_available }}
                  </td>
                </tr>
                <tr>
                  <th>Dinero envío:</th>
                  <td>
                    {{ creditTransferEditable.amount_to_send }}
                  </td>
                </tr>
                <tr>
                  <th>Transferencia a realizar:</th>
                  <td>
                    <v-text-field
                      v-model.number="creditTransferEditable.amount_to_transfer"
                      type="number"
                      filled
                      dense
                      class="mt-2 mb-n4"
                      :readonly="!editingMoneyTransfer"
                      :rules="formRules.numberRulesAllowZero"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Compensación cuentas cuotas:</th>
                  <td>
                    <v-text-field
                      v-model.number="creditTransferEditable.compensation_fees"
                      type="number"
                      filled
                      dense
                      class="mt-2 mb-n4"
                      :readonly="!editingMoneyTransfer"
                      :rules="formRules.numberRulesAllowZero"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Compensación cuentas desembolsos:</th>
                  <td>
                    <v-text-field
                      v-model.number="creditTransferEditable.compensation_outlays"
                      type="number"
                      filled
                      dense
                      class="mt-2 mb-n4"
                      :readonly="!editingMoneyTransfer"
                      :rules="formRules.numberRulesAllowZero"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Dialog change credit transfer to sent -->
    <DialogBox :model="dialogChangeToSent" :color="'warning'">
      <template slot="toolbar">
        <span>¿Estás seguro de pasar a enviado este envío?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogChangeToSent = false">Cerrar</v-btn>
        <v-btn text @click="(dialogChangeToSent = false), changeMoneyTransferStatusToSent()">pasar a enviado</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog delete credit transfer -->
    <DialogBox :model="dialogDeleteCreditTransfer" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar este envío?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDeleteCreditTransfer = false">Cerrar</v-btn>
        <v-btn text @click="(dialogDeleteCreditTransfer = false), deleteMoneyTransfer()">eliminar</v-btn>
      </template>
    </DialogBox>

    <div v-if="editingMoneyTransfer" class="text-right mt-4">
      <v-btn small @click="cancelHandler()" class="mr-4">cancelar</v-btn>
      <v-btn small :disabled="needToRecalculate" @click="editMoneyTransfer()">guardar</v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Banner from "@/components/elements/Banner";
import ProjectSummaryTable from "@/components/elements/credit-transfers/ProjectSummaryTable.vue";
import DialogBox from "@/components/elements/DialogBox";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import * as currency from "currency.js";
import _ from "lodash";

export default {
  name: "creditTransfer",
  mixins: [FormRulesMixin, FormatDateMixin],
  components: {
    Banner,
    ProjectSummaryTable,
    DialogBox
  },
  data() {
    return {
      creditTransferEditable: {},
      panelOffices: [],
      editingMoneyTransfer: false,
      needToRecalculate: false,
      formAmountsSummaryValid: false,
      projectSummaryTableKey: false,

      // Banner actions permissions
      permissionsBannerActions: {
        changeToSent: ["SUPERADMIN"],
        byStatus: {
          CREATED: {
            edit: ["SUPERADMIN"],
            delete: ["SUPERADMIN"]
          },
          SENT: {
            edit: ["SUPERADMIN"],
            delete: ["SUPERADMIN"]
          }
        }
      },

      // Dialogs
      dialogChangeToSent: false,
      dialogDeleteCreditTransfer: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices
    }),
    _() {
      return _;
    }
  },
  async created() {
    this.creditTransferEditable = await this.$store.dispatch("creditTransfers/getCreditTransfer", this.$route.params.id);
    await this.$store.dispatch("partners/getPartners");
    await this.$store.dispatch("currencies/getCurrencies");
    await this.$store.dispatch("offices/getOffices", { filter: { "partner.id": this.creditTransferEditable.partner_id } });
  },
  methods: {
    ...mapActions({
      getTotalAmountSentByOffice: "creditTransfers/getTotalAmountSentByOffice"
    }),

    async changeMoneyTransferStatusToSent() {
      await this.$store.dispatch("creditTransfers/changeMoneyTransferStatusToSent", this.creditTransferEditable.id);
      this.creditTransferEditable = await this.$store.dispatch("creditTransfers/getCreditTransfer", this.$route.params.id);
      this.projectSummaryTableKey = !this.projectSummaryTableKey; // This is a trick for remount projectSummaryTable component and call again its created()
    },

    async deleteMoneyTransfer() {
      await this.$store.dispatch("creditTransfers/deleteMoneyTransfer", this.creditTransferEditable.id);
      this.$router.push("/envios");
      this.dialogDeleteCreditTransfer = false;
    },

    // Calculate data of an pack
    async calculateData() {
      this.creditTransferEditable.amount_sent = await this.getAmountSent();
      this.creditTransferEditable.bank_amount_available = await this.getBankAmountAvailable();
      this.creditTransferEditable.amount_to_send = this.getAmountToSend();
      this.creditTransferEditable.amount_to_transfer = await this.getAmountToTransfer();
      this.creditTransferEditable.compensation_fees = this.getCompensationFees();
      this.creditTransferEditable.compensation_outlays = await this.getCompensationOutlays();
      this.needToRecalculate = false;
    },

    // Return total amount available from balance + bail of each account
    totalAccountsAvailable() {
      let totalBalance = 0;
      let totalBail = 0;

      for (const account of this.creditTransferEditable.accounts) {
        totalBalance += account.balance;
        totalBail += account.bail;
      }

      return totalBalance - totalBail;
    },

    async cancelHandler() {
      this.creditTransferEditable = await this.$store.dispatch("creditTransfers/getCreditTransfer", this.$route.params.id);
      this.editingMoneyTransfer = false;
      this.needToRecalculate = false;
      this.projectSummaryTableKey = !this.projectSummaryTableKey; // This is a trick for remount projectSummaryTable component and call again its created()
    },

    async editMoneyTransfer() {
      if (this.$refs.formAmountsSummary.validate()) {
        const creditTransferDataFormatted = this._.cloneDeep(this.creditTransferEditable);
        creditTransferDataFormatted.amount_to_send = currency(creditTransferDataFormatted.amount_to_send).value;
        delete creditTransferDataFormatted.created_at;

        for (const project of creditTransferDataFormatted.credits_transfers) {
          for (const transfer of project.transfers) {
            transfer.amount_converted = currency(transfer.amount_converted).value;
            transfer.exchange_rate = currency(transfer.exchange_rate).value;
            this.$delete(transfer, "investor_first_name");
            this.$delete(transfer, "investor_last_name");
            this.$delete(transfer, "investor_type");
          }
        }

        if (
          creditTransferDataFormatted.amount_to_send >
          creditTransferDataFormatted.amount_to_transfer +
            creditTransferDataFormatted.compensation_fees +
            creditTransferDataFormatted.compensation_outlays +
            creditTransferDataFormatted.bank_amount_available
        ) {
          const errorMessage =
            "Disponible en banco + transferencia a realizar + compensación cuentas cuotas + compensación cuentas desembolsos debe ser mayor o igual a dinero envío";
          this.$store.commit("SET_SNACKBAR", { show: true, text: errorMessage, color: "error" });
        } else {
          await this.$store.dispatch("creditTransfers/editMoneyTransfer", creditTransferDataFormatted);
          this.editingMoneyTransfer = false;
        }
      }
    },

    removeProjectFromCreditTransfer(project) {
      const creditTransfers = this.creditTransferEditable.credits_transfers;
      const officesIds = this.creditTransferEditable.offices_ids;

      const indexTransferToRemove = creditTransfers.findIndex((e) => e.project_id === project.project_id);
      const indexOfficeIdToRemove = officesIds.findIndex((e) => e === project.office_id);
      creditTransfers.splice(indexTransferToRemove, 1);
      if (officesIds.length > 1) officesIds.splice(indexOfficeIdToRemove, 1);
    },

    // ---------------------- Calculated data ----------------------
    async getAmountSent() {
      const amountSent = await this.getTotalAmountSentByOffice(this.creditTransferEditable.offices_ids);
      return amountSent;
    },

    async getBankAmountAvailable() {
      const amountSent = await this.getTotalAmountSentByOffice(this.creditTransferEditable.offices_ids);
      const bankAmountAvailable = amountSent + this.totalAccountsAvailable();

      if (bankAmountAvailable >= 0) return bankAmountAvailable;
      else return 0;
    },

    getAmountToSend() {
      let amount = 0;

      for (const project of this.creditTransferEditable.credits_transfers) {
        for (const transfer of project.transfers) {
          amount = currency(amount).add(currency(transfer.amount_converted, { fromCents: true }));
        }
      }

      return currency(amount);
    },

    async getAmountToTransfer() {
      const amountToSend = this.getAmountToSend();
      const bankAmountAvailable = await this.getBankAmountAvailable();

      if (amountToSend - bankAmountAvailable >= 0) return amountToSend - bankAmountAvailable;
      else return 0;
    },

    getCompensationFees() {
      let totalBalance = 0;
      let totalBail = 0;

      for (const account of this.creditTransferEditable.accounts) {
        if (account.type === "FEES") {
          totalBalance += account.balance;
          totalBail += account.bail;
        }
      }

      return totalBalance - totalBail;
    },

    async getCompensationOutlays() {
      let totalBalance = 0;
      let totalBail = 0;
      const amountSent = await this.getTotalAmountSentByOffice(this.creditTransferEditable.offices_ids);

      for (const account of this.creditTransferEditable.accounts) {
        if (account.type === "OUTLAYS") {
          totalBalance += account.balance;
          totalBail += account.bail;
        }
      }

      return totalBalance - totalBail - amountSent;
    }
  }
};
</script>
